<template>
    <div>
        <router-link v-bind:to="link">{{text}}</router-link>
    </div>
</template>

<script>
export default {
    name: 'Btn',
    props:{
        text: String,
        link: String
    }
}
</script>

<style scoped lang="scss">
div{
    height: 80px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}
a{
    padding: 15px 20px 15px 20px;
    background-color: #a5dfa5;
    border-radius: 59px;
    color: #000;
    font-weight: 600;
    font-size: 0.9rem;
    text-decoration: none;
    cursor: pointer !important;

    &:hover{
        transition: 400ms;
        background-color: #73b371;
    }
}
</style>