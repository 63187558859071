<template>
    <div class="hexagon"
    :style="
      [bgImage ? {backgroundImage: `url(${require('@/assets/images/' + bgImage)})`} : {backgroundImage: '#fff'},
      height ? {'max-height': height + 'px'} : {'height': '350px'},
      width ? {'max-width': width + 'px'} : {'width': '400px'},
      translateX && translateY ? {transform: `translate(${translateX}, ${translateY})`} : {transform: `translate(0, 0)`},
      bgColor ? {'background-color': bgColor} : {'background-color': 'none'},
      zindex ? {'z-index': zindex} : {'z-index': 'none'},
      ]"
    >
    </div>
</template>

<script>
export default {
    name:'Hexagon',
    props:{
        bgImage: String,
        height: String,
        width: String,
        translateX: String,
        translateY: String,
        bgColor: String,
        zindex: String
    }
}
</script>

<style scoped lang="scss">
.hexagon {
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
  background-position: center;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}
</style>
