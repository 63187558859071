<template>
    <Tiles/>
    <AboutCompany/>
    <Projects/>
</template>

<script>
import Tiles from '../components/Tiles'
import AboutCompany from '../components/AboutCompany'
import Projects from '../components/Projects'

export default {
    name: 'Home',
    components:{
        Tiles,
        AboutCompany,
        Projects
    }
}
</script>

<style lang="scss">

</style>
