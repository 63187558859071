<template>
  <div class="container">
    <div class="row">
      <div class="col-12 element">
        <div class="heroText">
          <h2 class="">Piana o <strong>zamkniętych komórkach</strong></h2>
          <h5>
            Zawiera pęcherzyki gazu, dzięki którym istnieje możliwość uzyskania
            odpowiednich właściwości izolacyjnych. Pianka posiadająca zamknięte
            komórki stanowi barierę dla wody oraz powietrza. Wielokrotne badania
            wykazały, że pianka poliuretanowa posiada najlepsze parametry
            izolacyjne oraz wytrzymałościowe.
          </h5>
        </div>
      </div>
      <div class="col-12 element">
        <div class="heroText">
          <h2 class="">Piana <strong>otwarto-komórkowa</strong></h2>
          <h5>
            Jest bardziej wydajna i ekonomiczna. Stanowi wspaniałą barierę
            termoizolacyjną i powietrzną. Dodatkowo pianka wykazuje lepsze
            właściwości dla przepuszczania pary wodnej. Pianka poliuretanowa
            stanowi skuteczną barierę dźwięku w przeciwieństwie do pianki o
            zamkniętych komórkach.
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TypesFoam",
};
</script>

<style scoped lang="scss">
.row {
  margin-top: 60px;
  margin-bottom: 60px;
  row-gap: 15px;
  position: relative;
}

.element {
  background-position: center;
  background-size: cover;
  position: relative;
  padding: 0;
  height: 400px;
  cursor: pointer;

  .heroText {
    opacity: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(221, 221, 221, 0.479);
  }

  &:hover {
    .heroText {
      opacity: 1;
      transition: 0.4s;
    }
  }
}
.element:nth-child(1) {
  background-image: url("../assets/images/Open-cell-foam.jpg");
}
.element:nth-child(2) {
  background-image: url("../assets/images/piana-otwrto-komorkowa.jpg");
}
</style>