<template>
    <router-link v-bind:to="hyperlink">
        <div class="oneTile" v-bind:style="[bgColor ? {backgroundColor: `${bgColor}`} : {backgroundColor: `none`}]">
            <div class="tileInfo" v-bind:style="[bgImage ? {backgroundImage: `url(${require('@/assets/' + bgImage)})`} : {backgroundImage: `none`}]">
                <img :src="require(`../assets/icons/${icon}`)" v-if="icon" class="icon"/>
                <h3>{{title}}</h3>
                <p>{{description}}</p>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'Tile',
    props:{
        title: String,
        description: String,
        bgColor: String,
        bgImage: String,
        hyperlink: String,
        icon: String
    }
}
</script>

<style scoped lang="scss">

.icon{
    position:absolute;
    transform: translateY(-130%);
    height: 60px !important;
    width: 60px !important;
}

@keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}
a{
    text-decoration: none;
}
.tileInfo{
    background-size: cover;
    position: relative;

    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: flex-end;

    text-align: center;

    h3{
        color: white;
        line-height: 3;
        z-index: 1;
    }
    p{
        display: none;
        opacity: 0;
        font-size: 0.9rem;
    }

    &:before{
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: black;
        opacity: 0.2;
        z-index: 0;
    }
}
.icon {
        color: white;
        height: 50px;
        width: 50px;
    }

@mixin TileHover {
    .tileInfo{
        align-items: center;

        .icon{
            display: none;
        }
        h3{
            display: none;
        }
        p{
            display: block;
            animation: fadeIn 1 1s forwards;
            color: white;
            z-index: 2;
            padding: 15px;
        }

        &:before{
            transition: 1s;
            opacity: 0.7;
        }
    }
}

.oneTile{
    width: 100%;
    height: 100%;
    cursor: pointer;
    
    &:hover{
        @include TileHover();
    }
}
</style>