<template>
  <section>
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-lg-6 heroHexagon">
          <Hexagon bgImage="3dprinterschool.png" height="400" width="500" />
          <Hexagon
            bgImage="druk3d.jpg"
            height="230"
            width="300"
            translateX="90px"
            translateY="150px"
          />
        </div>
        <div class="col-sm-12 col-lg-6 heroText">
          <h2>Druk <strong>3D</strong></h2>
          <h5>
            Wydruki trójwymiarowe wykorzystywane są dziś niemal we wszystkich
            branżach. W motoryzacji tworzy się w ten sposób części zamienne oraz
            niewielkie elementy wyposażenia. Architekci „drukują” modele
            inwestycji, w celu dokonania ich wizualizacji. Lekarze tworzą
            wydruki 3D, aby przygotować plany przeprowadzanych zabiegów.
            Wykorzystanie tej technologii ogranicza tylko nasza wyobraźnia.
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-lg-6 heroText">
          <h3>Technologia <strong>druku 3D</strong></h3>
          <p>
            Została zapoczątkowana przez tzw. stereolitografię (SLA) już w
            latach 80-tych XX wieku. Metoda ta jest wykorzystywana również w
            dzisiejszych czasach i polega na tym, że wiązka lasera utwardza
            wybrane fragmenty przygotowanej wcześniej żywicy światłoutwardzalnej
            w stanie płynnym. W efekcie utwardzenia, otrzymuje się trójwymiarowy
            obiekt w stanie stałym. Technika ta pozwala osiągnąc bardzo dużą
            precyzję, stworzony obiekt wymaga jednak dodatkowej obróbki już po
            samym etapie drukowania (tzw. post processing).
          </p>
          <p>
            Podobną do SLA jest technika SLM, czyli selektywne topienie
            laserowe. Do procesu przygotowywany jest sproszkowany materiał (np.
            metalowy), który następnie przy pomocy lasera jest topiony. Po
            ustaniu jego oddziaływania stop zastyga, tworząc trójwymiarowy
            obiekt. Zaletą tej metody jest możliwość stworzenia modelu o bardzo
            skomplikowanej geometrii.
          </p>
        </div>
        <div class="col-sm-12 col-lg-6 heroHexagon">
          <Hexagon bgImage="druk3d2.jpg" height="400" width="500" />
          <Hexagon
            height="200"
            width="250"
            bgImage="3dprinter.png"
            translateX="-140px"
            translateY="150px"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 heroText">
          <h2 class="text-center">
            FDM - <strong>popularna technika drukowania w 3D</strong>
          </h2>
          <h5>
            W metodzie tej wykorzystuje się drukarkę, wyposażoną w niewielką
            głowicę wytłaczającą materiał termoutwardzalny (filament). Pierwszym
            etapem tego procesu jest stworzenie, lub użycie przygotowanego
            wcześniej, komputerowego modelu 3D. Następnym krokiem jest
            wykorzystanie tzw. slicera – jest to program, który „tnie”
            przygotowany model, na warstwy o odpowiedniej grubości. Im cieńsze,
            tym dokładniej odwzorujemy trójwymiarowy obiekt. To właśnie tymi
            warstwami nanoszone jest tworzywo termoutwardzalne. Oprócz tego
            slicer pozwala również na wskazanie grubości ścian obiektu, co
            przekłada się na jego wytrzymałość mechaniczną. Istotnym parametrem
            jest także wypełnienie – im gęstsze, tym obiekt będzie trwalszy, ale
            jego wykonanie zabierze więcej czasu oraz filamentu, a tym samym
            będzie bardziej kosztowne.
          </h5>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Hexagon from "../components/Hexagon";

export default {
  name: "Printing3d",
  components: {
    Hexagon,
  },
};
</script>

<style scoped lang="scss">
.row {
  margin-bottom: 60px;
}

@media (max-width: 768px) {
  .row {
    margin-bottom: 30px;
  }
}
</style>
