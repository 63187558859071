<template>
  <div class="tiles">
    <div class="container gridItem">
      <div class="item-1">
        <Tile
          title="Laboratorium"
          description="W laboratorium firmy Leda Polymer nasi specjaliści prowadzą liczne badania nad możliwymi surowcami do produkcji piany NIPU, a także możliwym późniejszym wykorzystaniem gotowych wyrobów."
          hyperlink="/laboratorium"
          bgImage="images/photo-laboratory.jpg"
        />
      </div>
      <div class="item-2">
        <Tile
          title="Piana poliuretanowa"
          description="Poliuretany to tworzywa sztuczne znajdujące szerokie zastosowanie - począwszy od pianek poliuretanowych (stanowiących 80% wszystkich poliuretanów) oraz powłok, aż do gąbek oraz kształtek."
          hyperlink="/piana-poliuretanowa"
          bgImage="images/polyurethane-foam.png"
        />
      </div>
      <div class="item-3">
        <Tile
          title="Druk 3D"
          description="Druk 3D to technologia, która pozwala na stworzenie dowolnego trójwymiarowego obiektu fizycznego, na bazie przygotowanego wcześniej modelu komputerowego."
          hyperlink="/druk-3d"
          bgImage="images/druk3d.jpg"
        />
      </div>
      <div class="item-4">
        <Tile
          title="Do pobrania"
          description="Zbiór plików do pobrania które mogą się przydać."
          hyperlink="/do-pobrania"
          bgColor="#102E5A"
          icon="arrow-down-circle.svg"
        />
      </div>
      <div class="item-5">
        <Tile
          title="Kontakt"
          description="Kontakt z nami jest możliwy na wiele sposobów. Jeżeli masz pytania, skorzystaj z któregoś z nich."
          hyperlink="/kontakt"
          bgColor="#CFF6CE"
          icon="message.svg"
        />
      </div>
      <div class="item-6">
        <Carousel />
      </div>
    </div>
  </div>
</template>

<script>
import Tile from "./Tile";
import Carousel from "./Carousel";

export default {
  name: "Tiles",
  components: {
    Tile,
    Carousel,
  },
};
</script>

<style lang="scss" scoped>
.tiles {
  width: 100%;
  margin-top: 60px;
}
.gridItem {
  display: grid;
  grid-template-columns: repeat(5, 20%);
  grid-template-rows: repeat(4, 150px);
}
.item-1 {
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: 3;
  grid-column-end: 2;
}

.item-2 {
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: 3;
  grid-column-end: 6;
}
.item-3 {
  grid-row-start: 3;
  grid-column-start: 1;
  grid-row-end: 5;
  grid-column-end: 4;
}
.item-4 {
  grid-row-start: 3;
  grid-column-start: 4;
  grid-row-end: 4;
  grid-column-end: 5;
}
.item-5 {
  grid-row-start: 3;
  grid-column-start: 5;
  grid-row-end: 4;
  grid-column-end: 6;
}
.item-6 {
  grid-row-start: 4;
  grid-column-start: 4;
  grid-row-end: 5;
  grid-column-end: 6;
}
@media screen and (max-width: 992px) {
  .gridItem {
    display: flex;
    flex-direction: column;
    div{
        height: 200px;
    }
  }
}
</style>

