<template>
    <footer>
            <div class="col-md-12 footerRow">
                    <div class="container d-flex flex-column flex-md-row pt-4 pb-4">
                        <div class="col-12 col-md-6">
                            <FooterMotto/>
                        </div>
                        <div class="col-12 col-md-6">
                            <FooterNav/>
                        </div>
                    </div>
            </div>
            <div class="col-12 footerRow">
                    <div class="container d-flex flex-column flex-md-row">
                        <div class="col-12 col-md-6 d-flex justify-content-center justify-content-md-start">
                            <p class="m-0 text-md-left">©2022 Leda Polymer | Wszelkie prawa zastrzeżone</p>
                        </div>
                        <div class="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
                            <p class="m-0">Projekt i realizacja: <a href="https://greennovention.eu" target="_blank" rel="nofollow">Greennovention</a></p>
                        </div>
                    </div>
            </div>
    </footer>
</template>

<script>
import FooterNav from '../components/FooterNav.vue'
import FooterMotto from '../components/FooterMotto.vue'

export default {
    name:'Footer',
    components:{
        FooterNav,
        FooterMotto
    }
}
</script>

<style scoped lang="scss">

footer{
    p{
        color:black;
        font-size: 14px;
    }

    .footerRow:nth-child(1){
        width: 100%;
        min-height: auto;
        background-color: #3C7673;

        div:nth-child(2){
            min-height: auto;
        }
    }

    .footerRow:nth-child(2){
        width: 100%;
        min-height: 50px;
        background-color: #359A95;
        display: flex;
        align-items: center;

        a{
            text-decoration: none;
            color: black;
        }
    }
}

@media (max-width: 768px) {
    footer{
            text-align: center;
    }
}
</style>