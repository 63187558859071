<template>
    <header>
        <div class="container d-flex justify-content-end p-0">
            <SocialIcons/>
        </div>
    </header>
</template>

<script>
import SocialIcons from './SocialIcons'

export default {
    name: 'Header',
    components: {
        SocialIcons
    }
}
</script>

<style scoped lang="scss">
header{
    background-color: #3C7673;
    color: white;
    height: 50px;
}
</style>