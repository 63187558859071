<template>
<div class="mottoWrapper">
    <div>
        <img src="../assets/images/LEDA-icon.png" alt="Leda polymer icona">
    </div>
    <div>
        <span class="motto"><em>Dzięki naszym rozwiązaniom, <br/>już dziś możemy lepiej dbać o środowisko </em></span>
        <BIcon-emoji-smile class="smile-icon"/>
    </div>
</div>
</template>

<script>
export default {
    name: 'FooterMotto'
}
</script>

<style scoped lang="scss">
.mottoWrapper{

    img{
        height: 80px;
        width: 80px;
    }
    span{
        color:rgb(39, 39, 39);
    }
    .smile-icon{
        color:rgb(39, 39, 39);
    }
}

@media (max-width: 768px) {
    .mottoWrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-bottom: 30px;
    }
}
</style>