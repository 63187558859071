<template>
    <nav>
        <ul class="navbar-nav">
            <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/nipu">Pianka NIPU</router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/ogrody-wertykalne">Ogrody wertykalne</router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/druk-3d">Druk 3D</router-link>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name:'FooterNav'

}
</script>

<style scoped lang="scss">
nav{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;

    .nav-link{
        color: #000;

        &:hover{
            font-weight: 600;
        }
    }
}

@media (max-width: 768px) {
    nav{
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}
</style>