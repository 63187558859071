<template>
</template>

<script>
 export default {
   name:'VerticalGardens',
    components: {
    }
  }
</script>

<style>

</style>