import "bootstrap/dist/css/bootstrap.min.css"
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import { createApp } from 'vue'
import './assets/reset.scss'
import App from './App.vue'

import router from "./router"

const app = createApp(App)
app.use(router)
app.use(BootstrapIconsPlugin)
app.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_MAP_KEY,
        language: 'pl',
    },
})
app.mount('#app')

import "bootstrap/dist/js/bootstrap.js"
