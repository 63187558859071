<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-lg-6 heroHexagon">
        <Hexagon bgImage="nipufoarm.jpg" height="400" width="500" />
        <Hexagon bgImage="nipu.jpg" height="230" width="300" translateX="90px" translateY="150px" />
      </div>
      <div class="col-sm-12 col-lg-6 heroText">
        <h2>Piana <strong>NIPU</strong></h2>
        <h5>
          Ekologiczna bezizocyjanianowa pianka poliuretanowa, produkowana z surowców odnawialnych, np. na bazie olejów
          roślinnych. Jest to idealna alternatywa, ze względu na coraz dalej posunięte działania na rzecz ochrony
          środowiska. Surowce do produkcji pianek NIPU mają od 60 do 80% biomasy. Zalicza się do niej, oprócz olejów
          roślinnych, m.in. miazga drzewna oraz cukry z produktów ubocznych przetwarzania roślin cukrowych. Pianka
          poliuretanowa z surowców naturalnych może być wykorzystywana w ten sam sposób, jak tradycyjna.
        </h5>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 heroText">
        <h2 class="text-center">Różne funkcje<strong> pianki NIPU</strong></h2>
        <h5>
          Dzięki swojej strukturze sprawdzi się jako izolator termiczny. Ponadto piana świetnie sprawdzi się także w
          celu wygłuszania pomieszczeń.

          Dzięki termoizolacyjności, NIPU będzie także świetną otuliną dla przewodów przesyłających np. ciepłą wodę.
          Zadziała to również w drugą stronę - pianka powstrzyma nagrzewanie się wody, która powinna pozostać zimna.

          Zdolności pianki NIPU do tłumienia drgań można również wykorzystać w przemyśle. Spełni doskonałą funkcję jako
          podkładka antywibracyjna dla ciężkich urządzeń przemysłowych. Może również odegrać kluczowe znaczenie dla
          zdrowia operatorów maszyn, wobec których drgania, w długofalowej perspektywie, są szkodliwe.
        </h5>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6 heroText">
        <h2>Nasze badania nad <strong>NIPU</strong></h2>
        <h5>
          Badania wykazały, że jest świetnym podłożem pod roślinność w ogrodach wertykalnych. Jej twardość
          sprzyja utrzymaniu rośliny w pionie, a pory pozwalają na ukorzenienie. Ponadto niosą one dodatkową wartość – w
          piance bez problemu jest w stanie zgromadzić się i utrzymać woda, niezbędna dla dobrej kondycji roślinności.

          Najnowsze badania, związane są z wykorzystaniem jej termoizolacyjnych właściwości w
          ramach okiennych. Będzie ona pełnić w nich rolę wypełnienia, przyczyniając się do oszczędności ciepła w
          pomieszczeniu.
        </h5>
      </div>
      <div class="col-sm-12 col-lg-6 heroHexagon">
        <Hexagon bgImage="kwiatek-20211021.jpg" height="400" width="500" />
        <Hexagon bgImage="sciana-vertykalna.png" height="200" width="250" translateX="-140px" translateY="150px" />
      </div>
    </div>
    <div class="row">
          <div class="col-sm-12 heroText">
            <h2 class="text-center"><strong>FDM</strong> - popularna technika drukowania w 3D</h2>
            <h5>
              W metodzie tej wykorzystuje się drukarkę, wyposażoną w niewielką głowicę wytłaczającą materiał termoutwardzalny (filament). Pierwszym etapem tego procesu jest stworzenie komputerowego modelu 3D. Możliwe jest także użycie już takiego, który jest przygotowany wcześniej. Następnym krokiem jest wykorzystanie tzw. slicera. Jest to program, który „tnie” przygotowany model na warstwy o odpowiedniej grubości. Im cieńsze, tym dokładniej odwzorowany trójwymiarowy obiekt. To właśnie tymi warstwami nanoszone jest tworzywo termoutwardzalne. Oprócz tego slicer pozwala również na wskazanie grubości ścian obiektu, co przekłada się na jego wytrzymałość mechaniczną. Istotnym parametrem jest także wypełnienie – im więcej tym znów obiekt będzie trwalszy, ale jego wykonanie zabierze więcej czasu oraz filamentu, a tym samym będzie bardziej kosztowne.
            </h5>
          </div>
      </div>
  </div>
</template>

<script>
import Hexagon from '../components/Hexagon'

export default {
  name: 'NipuFoam',
  components: {
    Hexagon
  }

}
</script>

<style lang="scss" scoped>
.row {
  margin-top: 60px;
  margin-bottom: 60px;
}
</style>