<template>
  <section>
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-lg-6 heroHexagon">
          <Hexagon bgImage="lab-280599.png" height="400" width="500" />
          <Hexagon
            bgImage="lab-434363.png"
            height="230"
            width="300"
            translateX="90px"
            translateY="150px"
          />
        </div>
        <div class="col-sm-12 col-lg-6 heroText">
          <h2>Laboratorium <strong>Leda Polymer</strong></h2>
          <h5>
            Stworzenie innowacyjnych produktów oraz ich skuteczna aplikacja w
            przemyśle, to zasługa specjalistów z naszego laboratorium.
            Rozwijanie nowoczesnych technologii, testy gotowych materiałów i
            badania nad coraz to nowymi surowcami do produkcji piany NIPU, to
            tylko niektóre z ich domen.
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 heroText">
          <h2 class="text-center">
            <strong>Produkcja </strong>pian poliuretanowych
          </h2>
          <h5>
            Z połączenia dwóch głównych surowców – poliolu z dwiema grupami
            wodorotlenowymi oraz izocyjanianu – powstają piany poliuretanowe. Od
            ilości grup wodorotlenowych poliolu, a także długości i budowy jego
            łańcucha węglowego, zależą późniejsze właściwości gotowej piany
            poliuretanowej.
          </h5>
          <h5>
            Reakcja chemiczna pomiędzy poliolem i izocyjanianem prowadzi do
            otrzymania poliuretanów. Najczęściej stosowanym sposobem jest metoda
            prepolimerowa. Dzieli się ona na dwa etapy, z czego produkt końcowy
            pierwszego stanowi <em>prepolimer</em>.
          </h5>
          <!-- <img src="../assets/images/model-1.png" alt="reakcja chemiczna" /> -->
          <h5>
            W drugim etapie grupy izocyjanianowe obecne w mieszaninie reakcyjnej
            reagują z wodą, wytwarzając dwutlenek węgla. Gaz wypełnia wolne
            przestrzenie w matrycy poliuretanu, przyczyniając się do
            powstawania, a dalej wzrostu piany poliuretanowej.
          </h5>
          <!-- <img src="../assets/images/model-2.png" alt="reakcja chemiczna" /> -->
          <h2 class="text-center mt-2 mt-lg-4">
            <strong>Dodatki </strong>mieszaniny reakcyjnej
          </h2>
          <h5>
            Prócz dwóch głównych surowców, stanowiących podstawę reakcji syntezy
            poliuretanu, mieszanina reakcyjna może zawierać różnego rodzaju
            dodatki, dzięki którym możemy uzyskać pożądane w gotowym wyrobie
            właściwości. Wśród nich wyróżnić można następujące grupy:
          </h5>
          <ul class="list-unstyled">
            <li>
              <BIcon-arrow-right-circle class="icon" />
              <strong>Substancje sieciujące</strong>
              – sprzyjają usztywnieniu gotowej piany poliuretanowej.
            </li>
            <li>
              <BIcon-arrow-right-circle class="icon" />
              <strong>Katalizatory</strong>
              – przyspieszają proces reakcji chemicznej oraz spieniania piany.
            </li>
            <li>
              <BIcon-arrow-right-circle class="icon" />
              <strong>Środki spieniające</strong>
              – substancje wywołujące wzrost piany poliuretanowej pod wpływem
              czynników fizycznych lub chemicznych.
            </li>
            <li>
              <BIcon-arrow-right-circle class="icon" />
              <strong>Surfaktanty</strong>
              – zmniejszają tarcie na powierzchni, wspierają tworzenie porów,
              stabilizują wolne przestrzenie piany, wpływają również na ich
              wielkość oraz przepuszczalność powietrza.
            </li>
            <li>
              <BIcon-arrow-right-circle class="icon" />
              <strong>Środki barwiące </strong> – nadają pianie wyjątkowy kolor.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Hexagon from "../components/Hexagon";

export default {
  name: "Labolatory",
  components: {
    Hexagon,
  },
};
</script>

<style scoped lang="scss">
.row {
  margin-bottom: 60px;
}
img {
  width: 100%;
  max-width: 700px;
  margin: 20px auto 20px auto;
}

.icon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  color: #1d98d4;
}
ul {
  li {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .row {
    margin-bottom: 30px;
  }
}
</style>
