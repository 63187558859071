<template>
    <div class="icons">
        <a href="https://twitter.com/ledapolymers" target="_blank" rel="nofollow">
            <div><BIcon-twitter class="twitter"/></div>
        </a>
        <a href="https://www.linkedin.com/in/leda-polymer-3749371b4" target="_blank" rel="nofollow">
            <div><BIcon-linkedin class="linkedin"/></div>
        </a>
    </div>
</template>

<script>
export default {
    name: 'SocialIcons'
}
</script>

<style scoped lang="scss">
.icons{
    display: flex;

    div{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin: 5px;
    padding: 10px;
    border-radius: 50%;

    &:hover{
        background-color: #f3ecdb;
    }
        .twitter, .linkedin {
        width: 20px;
        height: 20px;

        }

        .twitter{
            color:#1DA1F2;
        }

        .linkedin{
            color: #0e76a8;
        }
    }
}
</style>