<template>
<div class="container">
  <div class="wrapper">
    <div class="contactMap">
      <GMapMap
        :zoom="12"
        map-type-id="terrain"
        :center="mapPosition"
        class="map"
      >
        <GMapCluster :zoomOnClick="true">
          <GMapMarker
            :position="markerPosition"
            :clickable="true"
            :draggable="true"
            @click="center = markerPosition"
          />
        </GMapCluster>
      </GMapMap>
    </div>
    <div class="contactDetails">
      <h1>Info</h1>
      <h3>Leda Polymer sp. z o.o.</h3>
      <p><BIcon-geo-alt class="icon" /> Żeligowskiego 32/34/604, 90-643 Łódź</p>
      <p>
        <BIcon-envelope class="icon" />
        <a href="mailto:assistant@ledapolymers.eu" target="_blank" rel="nofollow"> assistant@ledapolymers.eu</a>
      </p>
      <p><BIcon-telephone class="icon" /> (+48) 530-398-197</p>
      <p><BIcon-info-circle class="icon" /> NIP: 8992797022</p>
      <p><BIcon-info-circle class="icon" /> REGON: 36527377800000</p>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Contact",
  data() {
    return {
      markerPosition: { lat: 51.76858486835736, lng: 19.4394688983106 },
      mapPosition: { lat: 51.76858486835736, lng: 19.4394688983106 },
    };
  },
};
</script>

<style scoped lang="scss">
.wrapper{
  margin-top: 60px;
  margin-bottom: 60px;
  width: 100%;
  height: 700px;
  display: flex;
  align-content: center;
  overflow: hidden;
  gap: 30px;

  .map{
    width: 100%;
    height: 100%;
  }

  .contactMap{
    width: 60%;
    height: 100%;
  }
  
  .contactDetails{
    width: 40%;
    height: 100%;
    padding: 30px;
    background-color: #cecece;
    color: rgb(37, 40, 44);
    line-height: 3em;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1{
      border-bottom: 5px solid #1d98d4;
      width: fit-content;
      margin-top: 0;
      margin-bottom: auto;
    }
    h3{
      margin-bottom: 30px;
    }
    p{
      align-items: center;
        a{
        text-decoration: none;
        color: #3C7673;
        transition: .3s;
        
        &:hover{
          color: #419e98;
        }
      }
    }
    p:nth-child(7){
      margin-bottom: auto;
    }

    .icon{
      width: 30px;
      height: 30px;
      color: #1d98d4;
    }
  }
}
@media (max-width: 1200px) {
.contactMap{
    width: 50%;
  }
  
  .contactDetails{
    width: 50%;
  }
}
@media (max-width: 992px) {
.wrapper{
  flex-direction: column;
  height: 1100px;
  .contactMap{
    height: 500px;
    width: 100%;
  }
  
  .contactDetails{
    align-items: center;
    text-align: center;
    height: 600px;
    width: 100%;
  }
}
}
</style>