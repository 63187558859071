<template>
  <div class="container projectItems">
    <div class="row">
      <div class="biomotive col-md-6 p-0">
        <div class="text">
          <p>
            BIOMOTIVE - Advanced biobased polyurethanes and fibres for the
            automotive industry with increased environmental sustainability.
          </p>
          <p>
            Leda Polymer była partnerem technologicznym w projekcie BIOMOTIVE,
            finansowanym przez BBI JU w ramach programu UE Horizon2020. Leda
            Polymer opracowała technologię produkcji pian NIPU o zawartości
            biomasy 80-90%. Dzięki uzyskanym właściwościom, bio-based NIPU z
            powodzeniem są stosowane jako element stabilizujący ogrodów
            wertykalnych.
          </p>
          <a
            href="https://biomotive.info"
            alt="biomotive website"
            target="_blank"
            rel="nofollow"
            >biomotive.info</a
          >
        </div>
      </div>
      <div class="frontship col-md-6 p-0">
        <div class="text">
          <p>
            Frontsh1p - A FRONTrunner approach to Systemic circular, Holistic &
            Inclusive solutions for a new Paradigm of territorial circular
            economy
          </p>
          <p>
            Leda Polymer jest partnerem projektu FRONTSH1P. Do zadań Ledy
            Polymer należy opracowanie technologii druku 3D z wykorzystaniem
            produktów pochodzących z recyklingu, które będą wykorzystane w
            procesie zielonej transformacji województwa łódzkiego, zapewniającej
            dekarbonizację oraz rewitalizację terytorialną.
          </p>
          <a
            href="https://frontsh1p.eu/"
            alt="frontsh1p website"
            target="_blank"
            rel="nofollow"
            >frontsh1p.eu</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Projects",
  components: {},
};
</script>

<style scoped lang="scss">
.projectItems {
  margin-top: 60px;
  margin-bottom: 60px;

  .text {
    opacity: 0;
    margin: 0;
    padding: 0 15px;
    font-size: 0.9rem;
    transition: 1s;
    color: white;
    z-index: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    a {
      font-weight: 700;
      text-decoration: none;
    }
  }

  div:nth-child(1) {
    min-height: 220px;

    .frontship {
      background-color: #b2cfdd;
      background-image: url("../assets/images/frontship-logo.png");
      background-position: center;
      background-size: cover;
      position: relative;
      cursor: pointer;

      display: flex;
      align-items: center;
      text-align: center;

      &:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: 1s;
        background-color: rgb(2, 59, 82);
      }

      &:hover {
        .text {
          opacity: 1;
        }

        &:before {
          opacity: 0.9;
          z-index: 0;
        }
      }
    }

    .biomotive {
      background-color: #b7ddb2;
      background-image: url("../assets/images/biomotive-logo.png");
      background-position: center;
      background-size: cover;
      position: relative;
      cursor: pointer;

      display: flex;
      align-items: center;
      text-align: center;

      &:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: 400ms;
        background-color: rgb(14, 65, 25);
      }

      &:hover {
        .text {
          opacity: 1;
        }

        &:before {
          opacity: 0.9;
          z-index: 0;
        }
      }
    }
  }
}
</style>
