<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-lg-6 heroHexagon">
        <Hexagon bgImage="polyurethane-foam.png" height="400" width="500" />
        <Hexagon
          bgImage="foam-3291443_1280.jpg"
          height="230"
          width="300"
          translateX="90px"
          translateY="150px"
        />
      </div>
      <div class="col-sm-12 col-lg-6 heroText">
        <h2>Materiały <strong>poliuretanowe</strong></h2>
        <h5>
          Stanowią gałąź technologii polimerów, która bardzo prężnie rozwija się
          już od prawie dwóch wieków. Podstawą do otrzymania poliuretanów są
          związki chemiczne określane jako poliole oraz izocyjaniany. Jako
          dodatki stosuje się także substancje sieciujące, katalizatory, środki
          spieniające, surfaktanty, antypireny, a także środki barwiące.
        </h5>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6 heroText">
        <h2>Piana poliuretanowa - <strong>biobased NIPU</strong></h2>
        <h5>
          Bezizocyjanianowa piana poliuretanowa NIPU produkowana jest z użyciem
          surowców odnawialnych (biobased), takich, jak oleje roślinne. Piana
          NIPU jest odpowiedzią na zmieniające się wymagania rynku oraz potrzebę
          zrównoważonej transformacji przemysłu oraz wyeliminowaniem
          wykorzystania paliw kopalnianych. Surowce roślinne wykorzystywane w
          produkcji NIPU to przede wszystkim epoksydowany olej sojowy oraz
          rycynowy. Podstawą produkcji materiałów tego rodzaju są ekologiczne
          surowce, zawierające od 60 do 80% biomasy, takiej jak miazga drzewna,
          oleje roślinne (z roślin oleistych uprawianych na nieużytkach rolnych)
          a także cukry z produktów ubocznych przetwarzania roślin cukrowych.
        </h5>
      </div>
      <div class="col-sm-12 col-lg-6 heroHexagon">
        <Hexagon bgImage="nipu.jpg" height="400" width="500" />
        <Hexagon
          bgImage="nipufoarm.jpg"
          height="200"
          width="250"
          translateX="-140px"
          translateY="150px"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 heroText">
        <h2 class="text-center">
          Różnica pomiędzy
          <strong>tradycyjną pianą poliuretanową a NIPU</strong>
        </h2>
        <h5>
          Tradycyjne produkty często produkowane są przy użyciu szkodliwych dla
          zdrowia i środowiska izocyjanianów. W przypadku NIPU mamy do czynienia
          z surowcami pochodzenia naturalnego. Ma to wpływ także na zdrowie
          pracowników zatrudnionych przy wytwarzaniu pianek. Badania wskazały
          również, iż odporność chemiczna piany NIPU jest nawet do 50% większa,
          niż standardowej piany poliuretanowej.
        </h5>
        <br /><br />
        <h2 class="text-center">Zastosowania i <strong>właściwości</strong></h2>
        <h5>
          Piana poliuretanowa jest wszechstronnym materiałem, który pozwala na
          różnorodne zastosowania. Cechuje się bardzo dobrą izolacyjnością
          termiczną oraz akustyczną. Z termoizolacją doskonale radzą sobie piany
          z zamkniętą strukturą komórkową, podczas gdy te z otwartą, doskonale
          pochłaniają dźwięki. Piana PUR umożliwia dobrą dyfuzję powietrza,
          łącznie z przepuszczaniem pary wodnej, dzięki czemu nie występuje
          ryzyko zawilgocenia powierzchni pod nią. Jednocześnie bardzo łatwo ją
          zastosować, nawet na najbardziej skomplikowanych konstrukcjach.
          Dodatkowo jest doskonale przyczepna do każdego podłoża i usztywnia
          konstrukcję. Posiada świetne właściwości penetrujące, wypełniając
          wszystkie wolne przestrzenie. Wykorzystując piankę poliuretanową do
          izolacji termicznej rur (np. z ciepłą wodą), nie ma żadnego ryzyka,
          wiążącego się ze szkodliwym działaniem wycieków na otulinę. Dzieje się
          tak, gdyż nasiąkliwość tradycyjnej piany wynosi około 2%. Kolejną
          cechą charakterystyczną piany PUR jest wysoka odporność na wpływ
          środowiska (w tym czynniki atmosferyczne), na uszkodzenia mechaniczne,
          takie jak ścieranie, czy różnego rodzaju substancje chemiczne, typu
          rozcieńczone kwasy i zasady, rozpuszczalniki oraz oleje i smary.
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
import Hexagon from "../components/Hexagon";
export default {
  name: "PolyurethaneFoam",
  components: {
    Hexagon,
  },
};
</script>

<style scoped lang="scss">
.row {
  margin-bottom: 60px;
}

.myButton {
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  .row {
    margin-bottom: 30px;
  }
}
</style>
