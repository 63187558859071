<template>
  <div class="slide-wrapper" id="carousel">
    <transition-group name="slider" tag="div">
        <div class="slide-item" v-for="(item, index) in sliderList" :key="index" v-show="currentSlider === index">
              <router-link class="link" v-bind:to="item.url">
                  <h3>{{item.title}}</h3>
              </router-link>
                  <img :src="require(`../assets/images/tileslider/${item.images}`)">
        </div>
    </transition-group>
        <div class="pagination">
          <span v-for="(dot, index) in getSlideCound" 
          :key="index" 
          :class="{active : index === currentSlider}"
          @click="goToSlide(index)"
          >
          </span>
        </div>
  </div>
</template>

<script>
import { ref, onMounted} from 'vue';

export default {
    name: 'Carousel',
    components: {
    },
setup(){
  const currentSlider = ref(0);
  const getSlideCound = ref(0);
  const autoPlayEnabled = ref(true);
  const timeDuration = ref(5000);

  const nextSlide = () => {
    if(currentSlider.value === getSlideCound.value - 1){
      currentSlider.value = 0;
      return;
    }
    currentSlider.value += 1;
  };

  const goToSlide = (index) => {
    currentSlider.value = index;
  };

  if(autoPlayEnabled.value){
      setInterval(() => {
      nextSlide() ;
    }, timeDuration.value);
    }

  onMounted(()=>{
    getSlideCound.value = document.querySelectorAll(".slide-item").length; 
  });


  return { currentSlider, getSlideCound, goToSlide };
},

data () {
   return {
     sliderList:[
       {
         title: 'Rodzaje pianek poliuretanowych',
         url: '/rodzaje-pian',
         images: 'rodzaje-pianek.png'
       },
       {
         title: 'Proces budowy ogrodu wertykalnego',
         url: '/ogrody-wertykalne',
         images: 'ogrody-wertykalne.jpg'
       },
       {
         title: 'Piana NIPU',
         url: '/nipu',
         images: 'nipu.jpg'
       }
     ]
    }
},
}
</script>

<style scoped lang="scss">
.slide-wrapper{
    height: 100%;
    width: 100%;
    position: relative;

    .slide-item{
      
      .link{
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          text-decoration: none;
          z-index: 2;
          
          h3{
            color: #000;
            text-align: center;
            background-color: rgba(255, 250, 252, 0.65);
            padding: 5px 10px 5px 10px;
          }
      }
    
      img{
        position: absolute;
        z-index: 1;
        top:0;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .pagination{
      position: absolute;
      bottom: 10px;
      width: 100%;
      display: flex;
      gap: 16px;
      justify-content: center;
      align-items: center;
      z-index: 2;

      span{
        cursor: pointer;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
      }

      .active{
        background-color: #102E5A;
      }
    }

  .slider-enter-active,
  .slider-leave-active {
    transition: all 0.7s ease-in-out;
  }

  .slider-enter-from,
  .slider-leave-to {
    opacity: 0;
  }
}

</style>