<template>
<section>
  <div class="container">
      <div class="row">
          <div class="col-sm-12 col-lg-6 heroHexagon">
            <Hexagon
            bgImage="whatwedo-10872.jpg"
            height="400"
            width="500"
            />
            <Hexagon
            bgImage="whatwedo-334175.png"
            height="230"
            width="300"
            translateX="90px"
            translateY="150px"
            />
          </div>
          <div class="col-sm-12 col-lg-6 heroText">
            <h2>Przyjazna dla środowiska <strong>alternatywa</strong></h2>
            <h5>
              Zajmujemy się produkcją bezizocyjanianowej pianki poliuretanowej NIPU, będącej alternatywą dla tradycyjnego poliuretanu. Ten innowacyjny produkt może znaleźć zastosowanie w branży samochodowej (w siedzeniach), do siodełek rowerowych, w odzieży, termoizolacjach oraz jako materiał wygłuszający.
            </h5>
          </div>
      </div>
      <div class="row">
          <div class="col-sm-12 col-lg-6 heroText">
            <h2>Wykorzystanie pianki <strong>NIPU</strong></h2>
            <h5>
            Stosowana jest również jako wkład ogrodów wertykalnych. Właściwości, a w szczególności jej sztywność oraz zdolność utrzymywania wody, przekłada się na zapewnienie roślinom optymalnych warunków do rozwoju i zachowania dobrej kondycji.
            </h5>
          </div>
          <div class="col-sm-12 col-lg-6 heroHexagon">
            <Hexagon
            bgImage="VerticalGarden.jpg"
            height="400"
            width="500"
            />
            <Hexagon
            bgImage="foam-3291443_1280.jpg"
            height="200"
            width="250"
            translateX="-140px"
            translateY="150px"
            />
          </div>
      </div>
      </div>
      <div class="row pictures">
        <div class="col-sm-12 col-md-6"></div>
        <div class="col-sm-6"></div>
        <div class="col-sm-6 col-md-4"></div>
        <div class="col-sm-6 col-md-4"></div>
        <div class="col-sm-6 col-md-4"></div>
    </div>
</section>
</template>

<script>
import Hexagon from '../components/Hexagon'

export default {
  name:'WhatWeDo',
  components:{
    Hexagon
  }
}
</script>

<style scoped lang="scss">
.row{
  margin-bottom: 60px;
}

.pictures{
    width: 100%;
    height: 700px;
    margin: 0;

    div{
      background-position: center;
      background-size: cover ;
    }
    div:nth-child(1){
      background-image: url('../assets/images/about-pictures/2.png');
    }
    div:nth-child(2){
      background-image: url('../assets/images/about-pictures/5.png');
    }
    div:nth-child(3){
      background-image: url('../assets/images/about-pictures/3.png');
    }
    div:nth-child(4){
      background-image: url('../assets/images/about-pictures/1.png');
    }
    div:nth-child(5){
      background-image: url('../assets/images/about-pictures/4.png');
    }
}
.heroText:nth-child(2){
  h2{
    text-align: left;
  }
}

@media (max-width: 768px) {
  .row{
  margin-bottom: 30px;
}
}
</style>