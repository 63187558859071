<template>
    <section>
        <div class="container info">
            <p>
                Leda Polymer to przedsiębiorstwo specjalizujące się w innowacyjnych i indywidualnie dopasowanych rozwiązaniach z zakresu produkcji piankowych form. Głównymi produktami są: przyjazna środowisku pianka poliuretanowa NIPU, a także formy wykorzystywane przy realizacji ogrodów wertykalnych.
            </p>
            <p>
                Jedną z misji firmy Leda Polymer jest ochrona przyrody. Sprzyja temu budowanie różnorodnych aranżacji z wykorzystaniem nowoczesnej technologii. Rośliny stanowią naturalny i ważny element środowiska, który poprawia klimat, zwłaszcza w aglomeracji miejskiej.
            </p>
            <Btn text="Zapoznaj się z Leda Polymer" link="/o-nas"/>
        </div>
    </section>
</template>

<script>
import Btn from "./Btn.vue"

export default {
    name: 'AboutCompany',
    components:{
        Btn
    }
}
</script>

<style scoped lang="scss">
section{
    margin-top: 60px;
    width: 100%;
    min-height: 40vh;
    position: relative;

    background-image: url(../assets/images/24148335-photo-chemist.jpg);
    background-position: center;
    background-size: cover ;
    background-attachment: fixed;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;

    &:before
    {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgb(6, 75, 52);
        opacity: 0.8;
    }


    .info{
        height: 100%;
        width: 100%;
        padding: 20px;
        color: white;
        
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        
        p{
            text-align: center;
            z-index: 1;
        }
    }
}
</style>