<template>
<nav class="navbar navbar-expand-md navbar-light bg-light sticky-top" id="navigation">
    <div class="container p-0">
        <!-- Brand -->

        <a class="navbar-brand" href="/">
            <img id="logo" src="../assets/images/LEDA-POLYMER-LOGO.png">
        </a>

        <!-- Toggler/collapsibe Button -->
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
            <span class="navbar-toggler-icon"></span>
        </button>

        <!-- Navbar links -->
        <div class="collapse navbar-collapse justify-content-md-end" id="collapsibleNavbar">
            <ul class="navbar-nav">
            <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/">Strona główna</router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/o-nas">O nas</router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/co-robimy">Co robimy</router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/laboratorium">Laboratorium</router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/kontakt">Kontakt</router-link>
            </li>
            </ul>
        </div>
    </div>
</nav>
</template>

<script>

export default {
    name: 'Nav',
    components: {
    }
}
document.addEventListener('scroll', function(e) {
        if (window.scrollY > 1) {
            document.getElementById('logo').style.width = "130px";
        }
        else
        {
            document.getElementById('logo').style.width = "170px";
        }
});

</script>

<style scoped lang="scss">
nav{
    width: 100%;
    height: auto;
    padding: 0;
    transition: .4s;

    img{
        width: 130px;
        transition: .4s;
    }
    .nav-link{
        text-align: center;
        
        a{
            font-weight: 500;
            cursor: pointer;
        }          
    }
}
</style>