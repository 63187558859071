<template>
  <section>
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-lg-6 heroHexagon">
          <Hexagon bgImage="about-1163891407.jpg" height="400" width="500" />
          <Hexagon
            bgImage="about-450596.jpg"
            height="230"
            width="300"
            translateX="90px"
            translateY="150px"
          />
        </div>
        <div class="col-sm-12 col-lg-6 heroText">
          <h2>Leda <strong>Polymer</strong></h2>
          <h5>
            Leda Polymer oferuje innowacyjne i indywidualnie dopasowane
            rozwiązania z zakresu produkcji
            <strong>pian poliuretanowych</strong>.
          </h5>
          <h5>
            Specjalizuje się w produkcji przyjaznej dla środowiska piany
            poliuretanowej <strong>NIPU</strong>. W zakres działalności firmy
            wchodzi także wykonywanie form do
            <strong>ogrodów wertykalnych</strong> oraz
            <strong>laboratoryjne badania</strong> nad przetwarzaniem tworzyw
            sztucznych dla różnorodnych zastosowań.
          </h5>
          <h5>
            Oferujemy także <strong>analizy techniczne</strong> i
            <strong>ewaluacje eksperckie</strong> w zakresie produkcji
            poliuretanów, szczególnie pian poliuretanowych.
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="heroText">
          <h2>Nasz <strong>cel</strong></h2>
          <h5>
            Stawiamy na rozwój i badania w zakresie nowoczesnych technologii
            polimerów. Nasze produkty i technologie pozwalają spełnić wysokie
            standardy ochrony środowiska, są oparte na odnawialnych surowcach,
            produkowane w systemie zrównoważonym. Naszą misją jest
            rozpowszechnić na rynku materiały poliuretanowwe nowej generacji,
            których wytwarzanie i późniejsza utylizacja są nieszkodliwe dla
            środowiska i klimatu, a także nie wykorzystują ograniczonych zasobów
            naturalnych.
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="heroText">
          <h2>Przyjazna dla środowiska <strong>alternatywa</strong></h2>
          <h5>
            Zajmujemy się produkcją bezizocyjanianowej piany poliuretanowej
            NIPU, będącej alternatywą dla tradycyjnego poliuretanu. Ten
            innowacyjny produkt może znaleźć zastosowanie w wielu gałęziach
            produkcji takich jak samochodowa, meblarska, budowlana, a także w
            ogrodach wertykalnych.
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="heroText">
          <h2>Wykorzystanie <strong>piany NIPU</strong></h2>
          <h5>
            Piana NIPU ze względu na swoje właściwości oferuje bardzo szeroki
            zakres zastosowania.
          </h5>
          <h5>
            Najczęściej piana poliuretanowa wykorzystywana jest w wykończeniu
            wnętrz. Umieszczana jest na różnorodne podłoża pod wykładzinę.
            Pianka może przybierać różne formy: płyt, granulatu lub pyłu. W
            zależności od swojej formy stosowana jest w miejscach trudno
            dostępnych (do ociepleń stosowany jest granulat), oraz w przemyśle
            chemicznym np. do produkcji past BHP.
          </h5>
          <h5>
            Pianka poliuretanowa stosowana jest również do produkcji siedzisk i
            oparć, wykorzystywanych między innymi w krzesłach, kanapach i
            materacach. Jej elastyczne właściwości sprzyjają wygodzie siedzenia
            i leżenia, a w przypadku materacy wspierają także zdrowe ułożenie
            kręgosłupa podczas spania.
          </h5>
          <h5>
            Stosowana jest również jako wkład ogrodów wertykalnych. Właściwości,
            a w szczególności jej sztywność oraz zdolność utrzymywania wody,
            przekłada się na zapewnienie roślinom optymalnych warunków do
            rozwoju i zachowania dobrej kondycji.
          </h5>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Hexagon from "../components/Hexagon";

export default {
  name: "About",
  components: {
    Hexagon,
  },
};
</script>

<style scoped lang="scss">
.row {
  margin-bottom: 60px;
}

.heroText:nth-child(1) {
  h2 {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .row {
    margin-bottom: 30px;
  }
}
</style>
