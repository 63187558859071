import { createRouter, createWebHistory } from "vue-router";
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import WhatWeDo from '../views/WhatWeDo.vue'
import Labolatory from '../views/Labolatory.vue'
import Contact from '../views/Contact.vue'
import Download from '../views/Download.vue'
import Printing3d from '../views/Printing3d.vue'
import PolyurethaneFoam from '../views/PolyurethaneFoam.vue'
import TypesFoam from '../views/TypesFoam.vue'
import VerticalGardens from '../views/VerticalGardens.vue'
import NipuFoam from '../views/NipuFoam.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/o-nas',
        name: 'About',
        component: About
    },
    {
        path: '/co-robimy',
        name: 'WhatWeDo',
        component: WhatWeDo
    },
    {
        path: '/laboratorium',
        name: 'Laboratory',
        component: Labolatory
    },
    {
        path: '/kontakt',
        name: 'Contact',
        component: Contact
    },
    {
        path: '/do-pobrania',
        name: 'Download',
        component: Download
    },
    {
        path: '/druk-3d',
        name: 'Printing3d',
        component: Printing3d
    },
    {
        path: '/piana-poliuretanowa',
        name: 'PolyurethaneFoam',
        component: PolyurethaneFoam
    },
    {
        path: '/rodzaje-pian',
        name: 'TypesFoam',
        component: TypesFoam
    },
    {
        path: '/ogrody-wertykalne',
        name: 'VerticalGardens',
        component: VerticalGardens
    },
    {
        path: '/nipu',
        name: 'NipuFoam',
        component: NipuFoam
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router 